import styled from "@emotion/styled";
import colors from "../colors";

const PageContainer = styled.div`
color: ${colors.text};

h3 {
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  display: inline-block;

  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 242px;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
  }
}
`;
const Slogan = styled.div`
height: 119px;
padding: 20px 0;
background-color: ${colors.highlight};
text-align: center;
display: none;

&.xs {
  display: block;
}

h2 {
  margin: 0;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 25px;
}
& > h3 {
  margin: 0;
  padding: 11px 30px 0;
  letter-spacing: 0.3px;
}

@media (min-width: 1224px) {
  display: block;

  &.xs {
    display: none;
  }

  h2 {
    padding: 24px 0 0;
  }
}
`;
const PreFooter = styled.div`
background-color: ${colors.text};
color: ${colors.highlight};
padding: 20px 0 30px;

@media (min-width: 320px) {
}
@media (min-width: 1224px) {
  display: flex;
  justify-content: center;
  padding: 50px 0 73px;
}

img {
  width: 235px;
  height: 133px;
  margin: auto;
  display: block;

  @media (min-width: 320px) {
  }
  @media (min-width: 1224px) {
    margin: 0;
    margin-right: 40px;
  }
}

ul {
  list-style: none;
  text-align: center;
  padding: 0;

  @media (min-width: 320px) {
  }
  @media (min-width: 1224px) {
    padding-left: 47px;
    margin: 17px 0 0;
    text-align: left;
    border-left: 2px solid ${colors.highlight};
  }

  li {
    font-size: 16px;
    margin: 2px 0;
  }

  a {
    color: ${colors.highlight};
    text-decoration: none;
  }
}
`;
const Footer = styled.div`
background-color: ${colors.highlight};
text-align: center;
color: ${colors.text};
padding: 22px 0 18px;
font-size: 15px;
letter-spacing: 0.2px;

p {
  margin: 0;
}
`;


const SlideContainer = styled.div`
  margin: 0;
`;

const Slide = styled.div`
  margin: 0 auto;
  display: block;
  position: relative;
  background: transparent;
  background-image: url('${props => props.image}');
  background-repeat: no-repeat;
  width: 100%;
  height: 380px;
  background-size: cover;
  background-position: center;

  & > .slide-text-wrapper{
    background-color: ${colors.text};
    color: ${colors.bg};
    position: absolute;
    padding: 10px;
    min-width: 15vw;

    p {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 22px;
      line-height: 1.3em;
      margin: 0;
    }
    p:nth-of-type(2){
      text-align:right;
      padding-left: 20px;
    }
    span{
      text-transform: none;
      font-weight:400;
    }
    span.nl{
      display: block;
      padding: 0 5vw;
      margin-left: 0 -5vw;
      text-align: center;
    }
    &.tl{
      top: 5%;
      left: 5%;
    }
    &.tc{}
    &.tr{
      top: 5%;
      right: 5%;
    }
    &.cl{
      top: 45%;
      left: 5%;
      transform: translateY(-50%);
    }
    &.cc{
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.cr{
      top: 45%;
      right: 5%;
      transform: translateY(-50%);
    }
    &.bl{
      bottom: 5%;
      left: 5%;
    }
    &.bc{
      bottom: 5%;
      left: 50%;
      transform: translateX(-50%);
    }
    &.br{
      bottom: 5%;
      right: 5%;
    }
    @media(min-width: 1224px){
      min-width: 220px;

      p{ font-size: 38px; }
      p:nth-of-type(2){ padding-left: 60px; }
      &.tl{
        top: 14%;
        left: 14%;
      }
      &.tc{}
      &.tr{
        top: 10%;
        right: 12%;
      }
      &.cl{
        top: 45%;
        left: 14%;
        transform: translateY(-50%);
      }
      &.cc{
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &.cr{
        top: 45%;
        right: 12%;
        transform: translateY(-50%);
      }
      &.bl{
        bottom: 10%;
        left: 14%;
      }
      &.bc{
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
      }
      &.br{}
    }
  }

  @media (min-width: 320px) {
  }
  @media (min-width: 1224px) {
    height: 739px;
  }
`;

const ProjectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & .carousel {
    width: 100%;
    height: 68vw;
    display: none;

    img {
      max-width: 100%;
    }

    @media (min-width: 1224px) {
      height: 312px;
      width: 477px;
    }
  }
  & .carousel:first-child {
    display:block;

    @media (min-width: 1224px) {
      display: none;
    }
  }

  &:nth-child(odd) .carousel:first-child {
    @media (min-width: 1224px) {
      display: block;
    }
  }
  &:nth-child(even) .carousel:last-child {
    @media (min-width: 1224px) {
      display: block;
    }
  }
`;
const ProjectData = styled.div`
  width: 479px;
  box-sizing: border-box;
  padding: 20px;
  text-align: left;

  h4 {
    color: ${colors.highlight};
    text-transform: uppercase;
    font-size: 25px;
    margin: 1em 0 0.6em;
  }
  h3 {
    margin: 0;

    &::after {
      transform: none;
      left: 0;
    }
    span {
      color: ${colors.highlight};
    }
  }
  .client {
    font-weight: bold;
  }

  p {
    font-size: 13px;
  }
  p.dev {
    margin-bottom: 0;
  }

  small {
    font-size: 11px;
    display: block;
  }
`;

const ClientSlide = styled.div`
width: 100vw;
height: 100vw;
background: url('${props => props.image}');
background-size: cover;

@media(min-width: 1240px) {
  width: 480px;
  height: 482px;
}`;

const ProjectsContainer = styled.div`
  display: flex;
  max-width: 960px;
  margin: 40px auto 45px;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const ProjectCategory = styled.div`
  width: 95%;
  height: 95vw;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-position: center;
  margin: auto;
  margin-bottom: 9px;
  position: relative;

  @media (min-width: 320px) {
  }
  @media (min-width: 1224px) {
    width: 315px;
    height: 315px;
  }
`;
const ProjectOverlay = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;

  h4 {
    font-size: 22px;
    font-weight: 900;
    width: 100%;
    margin: 0;
  }

  .icon {
    font-size: 40px;
    margin: 20px auto;
  }

  &:hover {
    opacity: 1;
    color: #fff;
    text-decoration: none;
  }
`;

export {
  ClientSlide,
  PageContainer,
  ProjectContainer,
  ProjectData,
  Slogan,
  Slide,
  SlideContainer,
  PreFooter,
  ProjectCategory,
  ProjectsContainer,
  ProjectOverlay,
  Footer
};
