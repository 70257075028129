const services = [
  { id: 1, id_category: 1, is_active: true, title: "Concepção de Marca" },
  { id: 2, id_category: 1, is_active: true, title: "Racional Criativo" },
  { id: 3, id_category: 1, is_active: false, title: "Campanhas" },
  { id: 4, id_category: 1, is_active: true, title: "Design Gráfico" },
  { id: 5, id_category: 1, is_active: false, title: "Peças Publicitárias" },
  { id: 6, id_category: 1, is_active: false, title: "Lançamento de Produto" },
  { id: 7, id_category: 4, is_active: true, title: "Redes Sociais" },
  { id: 8, id_category: 4, is_active: true, title: "Email Marketing" },
  { id: 9, id_category: 4, is_active: true, title: "Intitucionais / Responsivos" },
  { id: 10, id_category: 4, is_active: true, title: "SPAs / Portais" },
  { id: 11, id_category: 4, is_active: false, title: "SEO" },
  { id: 12, id_category: 4, is_active: false, title: "Portais" },
  { id: 13, id_category: 4, is_active: true, title: "APP Mobile / E-Commerce" },
  { id: 14, id_category: 4, is_active: false, title: "API's" },
  { id: 15, id_category: 4, is_active: false, title: "E-Commerce" },
  { id: 16, id_category: 4, is_active: true, title: "APIs / SEO / Integrações" },
  { id: 17, id_category: 7, is_active: true, title: "Realidade Aumentada" },
  { id: 18, id_category: 7, is_active: true, title: "Eventos Interativos" },
  { id: 19, id_category: 7, is_active: true, title: "Promoção de Produtos" },
  { id: 20, id_category: 7, is_active: false, title: "Ampliação de Informações" },
  { id: 21, id_category: 7, is_active: true, title: "Treinamentos" },
  { id: 22, id_category: 7, is_active: true, title: "Representações e Realidade Virtual" },
  { id: 23, id_category: 7, is_active: true, title: "Scanner 3D" },
  { id: 24, id_category: 3, is_active: true, title: "Impressos" },
  { id: 25, id_category: 3, is_active: true, title: "Catálogos / Banners" },
  { id: 26, id_category: 3, is_active: false, title: "Banners" },
  { id: 27, id_category: 3, is_active: true, title: "Folhetos / Folders / Flyers" },
  { id: 28, id_category: 3, is_active: true, title: "PDFs & PPTs" },
  { id: 29, id_category: 3, is_active: false, title: "PPTs" },
  { id: 30, id_category: 8, is_active: true, title: "Edição" },
  { id: 31, id_category: 8, is_active: true, title: "2D e 3D Motion Graphics" },
  { id: 32, id_category: 8, is_active: true, title: "Composição e Efeitos Visuais" },
  { id: 33, id_category: 8, is_active: true, title: "Vídeos Institucionais" },
  { id: 34, id_category: 8, is_active: false, title: "Vídeos Corporativos" },
  { id: 35, id_category: 8, is_active: false, title: "Vídeos Promocionais" },
  { id: 36, id_category: 8, is_active: false, title: "Vídeos Motivacionais" },
  { id: 37, id_category: 2, is_active: true, title: "Gerenciamento de Marca" },
  { id: 38, id_category: 2, is_active: true, title: "Identidade Visual (Guideline)" },
  { id: 39, id_category: 2, is_active: false, title: "Logotipos e Papelaria" },
  { id: 40, id_category: 2, is_active: false, title: "PDV" },
  { id: 41, id_category: 5, is_active: true, title: "Ilustração" },
  { id: 42, id_category: 5, is_active: true, title: "Pintura Digital" },
  { id: 43, id_category: 5, is_active: true, title: "Tratamento de imagem" },
  { id: 44, id_category: 5, is_active: false, title: "Desenho Concept" },
  { id: 45, id_category: 5, is_active: false, title: "Ativações com Arte" },
  { id: 46, id_category: 5, is_active: true, title: "Pintura Artística" },
  { id: 47, id_category: 5, is_active: true, title: "Decoração" },
  { id: 48, id_category: 6, is_active: true, title: "Projeto / Arquitetura" },
  { id: 49, id_category: 6, is_active: true, title: "Modelagem / Personagens" },
  { id: 50, id_category: 6, is_active: false, title: "Personagens" },
  { id: 51, id_category: 6, is_active: true, title: "Assets / Ambientes" },
  { id: 52, id_category: 6, is_active: true, title: "Texturização Realista" },
  { id: 53, id_category: 6, is_active: true, title: "Animação / Iluminação" },
  { id: 54, id_category: 6, is_active: false, title: "Iluminação" }
];

export default services;
