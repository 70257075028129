import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import colors from "../../colors";
import services from "../../stores/services";
import categories from "../../stores/categories";

const ServicesWrapper = styled.div`
  text-align: center;
  background-color: ${colors.text};
  color: ${colors.highlight};
  padding: 46px 0 24px;

  h3 {
    letter-spacing: 0.1px;

    &:after {
      background-color: ${colors.highlight};
    }
  }
  h4 {
    margin: 10px;
    color: #fff;
    text-transform: uppercase;
    font-size: 23px;
    font-weight: 900;
    letter-spacing: 0.6px;
  }
  p {
    font-size: 15px;
    padding: 8px 0;
    line-height: 18px;
    letter-spacing: 0.05px;
  }
`;

const ServicesContainer = styled.div`
  width: 96%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;

  @media (min-width: 320px) {
  }
  @media (min-width: 1224px) {
    width: 960px;
  }
`;
const ServiceCategory = styled.div`
  width: 45%;
  text-align: center;

  @media (min-width: 1224px) {
    width: 20.6%;
    text-align: left;
  }
`;
const Icon = styled.div`
  display: block;
  background-color: ${colors.highlight};
  color: ${colors.bg};
  width: 46px;
  height: 46px;
  border-radius: 25px;
  margin: 10px auto 0;
  font-size: 23px;
  line-height: 50px;
  text-align: center;

  @media (min-width: 1224px) {
    margin: 10px 0;
  }
`;
const Category = styled.h5`
  color: ${colors.highlight};
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0 0 7px;
  margin: 0;
`;
const ServicesList = styled.ul`
  list-style: none;
  font-size: 15px;
  color: ${colors.bg};
  padding: 0;
  margin: 0;
`;

export default function ServicesArea() {
  return (
    <ServicesWrapper id="area-servicos">
      <h3>Arte é o que fazemos</h3>
      <h4>Mãos criativas com foco em resultados</h4>
      <p>
        Na <b>Hands Criação</b> nosso compromisso é com a excelência focada nos
        resultados de nossos clientes.
        <br />
        Nossa equipe qualificada trabalha com criatividade, planejamento e
        estratégia na execução de todas as
        <br />
        ferramentas e recursos que disponibilizamos. Conheça um pouco mais o que
        fazemos e podemos fazer.
      </p>
      <ServicesContainer>
        {categories.map(category => (
          <ServiceCategory key={"category-" + category.id}>
            <Icon>
              {category.icon !== "" && <FontAwesomeIcon icon={category.icon} />}
            </Icon>
            <Category>{category.title}</Category>
            <ServicesList>
              {services
                .filter(service => service.id_category === category.id && service.is_active)
                .map(service => (
                  <li key={"service-" + service.id}>{service.title}</li>
                ))}
            </ServicesList>
          </ServiceCategory>
        ))}
      </ServicesContainer>
    </ServicesWrapper>
  );
}

ServicesArea.propTypes = {
  services: PropTypes.array
};
