const links = [
  {
    index: 1,
    address: "/",
    text: "Home"
    // items: [
    //   { index: 4, address: "portfolio#logos", text: "Logos" },
    //   {
    //     index: 5,
    //     address: "portfolio#ilustracoes",
    //     text: "Ilustrações"
    //   },
    //   {
    //     index: 6,
    //     address: "portfolio#identidade-visual",
    //     text: "Identidade visual"
    //   }
    // ]
  },
  {
    index: 2,
    address: "/sobre",
    text: "Hands Criação"
  },
  {
    index: 3,
    address: "/servicos",
    text: "O que fazemos"
  },
  //{
  //  index: 4,
  //  address: "/equipe",
  //  text: "Equipe"
  //},
  {
    index: 5,
    address: "/projetos",
    text: "Cases"
  },
  {
    index: 6,
    address: "/contato",
    text: "Contato"
  }
];

export default links;
