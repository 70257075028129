import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import colors from "../../colors";
import Project from "./Project";
import { Consumer } from "../../GeneralContext";

const Close = styled.a`
  position: fixed;
  right: 5%;
  top: 10px;
  font-size: 50px;
  text-decoration: none;
  color: ${colors.text};
  z-index: 999;

  &:hover {
    color: ${colors.text};
    text-decoration: none;
  }
`;
const ProjectContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 90px 0;
  overflow: auto;
  background: ${colors.bg};
  z-index: 100;
  opacity: ${props => (props.open ? 1 : 0)};
  pointer-events: ${props => (props.open ? "all" : "none")};
  transition: opacity 0.3s;
`;
const ProjectsWrapper = styled.div`
  margin: auto;
  width: 100%;

  .project {
    .swiper-container:nth-of-type(3) {
      display: none;
    }
  }

  @media (min-width: 320px) {
  }
  @media (min-width: 1224px) {
    width: 960px;

    .project:nth-of-type(even) {
      .swiper-container:nth-of-type(1) {
        display: none;
      }
      .swiper-container:nth-of-type(3) {
        display: block;
      }
    }
    .project:nth-of-type(odd) {
      .swiper-container:nth-of-type(3) {
        display: none;
      }
    }
  }
`;

export default class Projects extends React.Component {
  render() {
    const { projects } = this.props;
    return (
      <Consumer>
        {context => {
          const { projectsOpen } = context;

          return (
            <ProjectContainer open={projectsOpen}>
              <Close
                href="#close"
                onClick={e => {
                  e.preventDefault();
                  context.hideProjects();
                }}
              >
                &times;
              </Close>
              <ProjectsWrapper>
                {projects.length > 0 ? (
                  projects.map(project => (
                    <Project key={`project-${project.id}`} project={project} />
                  ))
                ) : (
                  <p>Ainda não foram inseridos projetos nessa categoria.</p>
                )}
              </ProjectsWrapper>
            </ProjectContainer>
          );
        }}
      </Consumer>
    );
  }
}

Projects.propTypes = {
  projects: PropTypes.array,
  open: PropTypes.bool
};
