import styled from "@emotion/styled";

import NormalImg from "../Img";

const Img = styled(NormalImg)`
  margin: 0 auto;
  display: block;
`;

export default Img;
