const slidesContext = require.context(`../../images/randomicas`, false, /.*\.jpg$/);
const slides = slidesContext.keys().map(image => require(`../../images/randomicas/${image.replace('./','')}`));

const slideDetails = [
  {
    image: null,
    align: "tr",
    mainText: "Mãos mundos",
    joiner: "criam",
    newLineJoiner: false
  },
  {
    image: null,
    align: "tl",
    mainText: "mãos conectam",
    joiner: "",
    newLineJoiner: false
  },
  {
    image: null,
    align: "cl",
    mainText: "Mãos apoiam",
    joiner: "",
    newLineJoiner: false
  },
  {
    image: null,
    align: "cr",
    mainText: "mãos juntas",
    joiner: "trabalham",
    newLineJoiner: false
  },
  {
    image: null,
    align: "cl",
    mainText: "mãos negócios",
    joiner: "celebram",
    newLineJoiner: false
  },
  {
    image: null,
    align: "tr",
    mainText: "mãos sucesso",
    joiner: "comemoram",
    newLineJoiner: true
  }
];

slides.forEach((image,index) => slideDetails[index].image = image);

export default slideDetails;
