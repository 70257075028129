import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { ClientSlide as Slide } from '../../components/StyledComponents';

const clientsContext = require.context("../../images", false, /clients.*\.jpg$/);
const clients = clientsContext.keys().map(image => require(`../../images/${image.replace('./', '')}`));

class ClientsArea extends React.Component {
  constructor(props) {
    super(props);
    this.swiper = null;
  }

  render() {
    const params = {
      controls: false,
      keyboard: false,
      indicators: false,
      interval: 3000
    };

    return (<div>
      <Carousel ref={this.swiper} {...params}>
        {clients.map((client, key) => {
          console.log(client);
          return <Carousel.Item>
            <Slide image={client} key={`client${key}`} alt="client group" />
          </Carousel.Item>
        })}
      </Carousel>
    </div>
    );
  }
}

export default ClientsArea;
