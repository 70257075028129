import React from "react";
import styled from "@emotion/styled";

import ClientsArea from "./ClientsArea";
import maestro from "../../images/maestro.jpg";
import colors from "../../colors";

const AboutWrapper = styled.div`
  max-width: 960px;
  padding: 50px 0 0;
  margin: auto;
  display: flex;
  flex-wrap: wrap;

  & > div {
    box-sizing: border-box;

    @media (min-width: 320px) {
    }
    @media (min-width: 1224px) {
      height: 482px;
      width: 50%;
    }
  }
  & > div:nth-of-type(1) {
    padding: 8px 20px 8px;

    h3 {
      margin: 0;
      letter-spacing: 0.3px;

      &:after {
        width: 182px;
      }
    }
    h4 {
      margin: 0;
      padding: 28px 0 17px;
      font-size: 23px;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0.7px;
      color: ${colors.highlight};
    }

    p {
      margin: 0 0 0.7em;
      font-size: 15px;
      word-spacing: 3.7px;
      line-height: 18px;
      text-align: left;
    }

    @media (min-width: 320px) {
    }
    @media (min-width: 1224px) {
      padding: 6px 10px 8px 0;

      p {
        text-align: left;
      }
    }
  }
  & > div:nth-of-type(2) {
    margin: 10px 0 0;
    background: url(${maestro});
    color: ${colors.bg};

    blockquote {
      margin: 30px 0 0 70px;
      font-style: italic;
      font-size: 18px;
      font-weight: 100;
      line-height: 23px;
      letter-spacing: 0.1px;
      word-spacing: 1px;
      position: relative;

      &:before {
        content: "“";
        font-size: 104px;
        font-weight: 400;
        line-height: 0;
        position: absolute;
        left: -60px;
        top: 26px;
      }
      &:after {
        content: "”";
        font-size: 104px;
        font-weight: 400;
        line-height: 0;
        position: absolute;
        right: 66px;
        bottom: -16px;
      }
    }
    p {
      padding: 5px 10%;
      font-size: 19px;
      font-style: italic;
      letter-spacing: -0.2px;
    }

    @media (min-width: 320px) {
    }
    @media (min-width: 1224px) {
      blockquote {
        margin: 30px 0 0 200px;
      }

      p {
        padding: 21px 0 0 69px;
      }
    }
  }
  & > div:nth-of-type(3) {
    padding: 0 30px 58px;
    box-sizing: border-box;
    background-color: ${colors.highlight};

    h3 {
      padding: 38px 0 0;
      color: ${colors.bg};

      &:after {
        background-color: ${colors.text};
        width: 127px;
        left: 0;
        transform: none;
      }
    }
    h4 {
      margin: 0.5em 0 0.9em;
      font-size: 23px;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }
    p {
      font-size: 15px;
      letter-spacing: 0.1px;
      line-height: 1.4em;
      text-align: justify;
    }

    @media (min-width: 1224px) {
      padding: 55px;

      p {
        text-align: left;
      }
    }
  }
  & > div:nth-of-type(4) {
    img {
      max-width: 100%;

      @media (min-width: 320px) {
      }
      @media (min-width: 1224px) {
      }
    }
  }

  h3 {
    text-align: left;

    &:after {
      background-color: ${colors.text};
      width: 110%;
      left: 0;
      transform: translateX(0);
    }
  }
`;

export default function AboutArea() {
  return (
    <AboutWrapper id="area-sobre">
      <div>
        <h3>Hands criação</h3>
        <h4>Um conceito</h4>
        <p>
          A <b>HANDS CRIAÇÃO</b> é uma combinação de{" "}
          <b>MÃOS E MENTES CRIATIVAS</b> que trabalham para oferecer as melhores
          soluções para os seus clientes. Criar e fazer acontecer é a nossa
          paixão.
        </p>
        <p>
          Trabalhamos com o conceito de inteligência coletiva, por isso temos
          como parceiros, profissionais com expertises diferenciados e vasta
          experiência no mercado da criatividade. Temos como valores a
          excelência em tudo o que fazemos, uma entrega rápida, comprometimento
          e eficiência para gerar resultados através das melhores soluções de
          comunicação.
        </p>
        <p>
          Ousada, moderna e cheia de energia, esta é a <b>Hands Criação.</b>
          <br />
          <br />
          Acreditamos que nosso trabalho é artesanal, por isso empenhamos
          capricho em cada detalhe de um projeto. Para nós, além de
          {' "'}surpreendente{'"'}, uma campanha publicitária tem que fortalecer
          a sua marca, e principalmente aumentar suas vendas.
        </p>
        <p>Deixe-nos mostrar um pouco mais!</p>
      </div>
      <div>
        <blockquote>
          Em um concerto muito
          <br />
          bem orquestrado
          <br />
          <br />
          Tudo começa e termina
          <br /> com as <b>mãos</b>
          <br />
          <br />
          As do maestro marcam
          <br />o tempo,
          <br />a intensidade,
          <br />o valor de cada nota,
          <br />
          <br />
          Outras executam a peça,
          <br />
          <br />
          Para que enfim, outras possam aplaudir o espetáculo.
        </blockquote>
        <p>
          É assim que pensamos a <b>Hands Criação</b>
        </p>
      </div>
      <div>
        <h3>Clientes</h3>
        <h4>Nossa maior motivação</h4>
        <p>Nosso foco é um ótimo atendimento.</p>
        <p>
          Nossa equipe já atendeu clientes de todos os portes e ramos de
          atuação, além de grandes marcas do mercado brasileiro.
        </p>
      </div>
      <ClientsArea />
    </AboutWrapper>
  );
}
