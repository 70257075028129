import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLightbulb,
  faAddressCard,
  faScroll,
  faDesktop,
  faPaintBrush,
  faCube,
  faVrCardboard,
  faVideo
} from "@fortawesome/free-solid-svg-icons";

const categories = [
  {
    id: 1,
    title: "Criação",
    icon: "lightbulb"
  },
  {
    id: 2,
    title: "Branding",
    icon: "address-card"
  },
  {
    id: 3,
    title: "Off-Line",
    icon: "scroll"
  },
  {
    id: 4,
    title: "Digital & Web",
    icon: "desktop"
  },
  {
    id: 5,
    title: "Arte (2D)",
    icon: "paint-brush"
  },
  {
    id: 6,
    title: "3D",
    icon: "cube"
  },
  {
    id: 7,
    title: "4D",
    icon: "vr-cardboard"
  },
  {
    id: 8,
    title: "Áudio & Vídeo",
    icon: "video"
  }
];

library.add(
  faLightbulb,
  faAddressCard,
  faScroll,
  faDesktop,
  faPaintBrush,
  faCube,
  faVrCardboard,
  faVideo
);

export default categories;
