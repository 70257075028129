const projectImagesContext = require.context("../images/projects", false, /project_.*\.jpg$/);
const projectImages = projectImagesContext.keys().map(image => require(`../images/projects/${image.replace('./','')}`));

const project_categories = [
  {
    id: 1,
    order: 2,
    title: "Identidade Visual",
    image: null,
    slug: "identidade-visual"
  },
  {
    id: 2,
    order: 1,
    title: "Ilustrações",
    image: null,
    slug: "ilustracoes"
  },
  {
    id: 3,
    order: 5,
    title: "Impressos",
    image: null,
    slug: "impressos"
  },
  {
    id: 4,
    order: 6,
    title: "Web",
    image: null,
    slug: "web"
  },
  {
    id: 5,
    order: 3,
    title: "3D",
    image: null,
    slug: "3d"
  },
  {
    id: 6,
    order: 4,
    title: "Branding",
    image: null,
    slug: "brand"
  },
  {
    id: 7,
    order: 7,
    title: "Mídias Sociais",
    image: null,
    slug: "midias-sociais"
  }
];
project_categories.forEach((cat,index) => cat.image = projectImages.filter(image => image.match(cat.slug.replace('-', '_'))));

export default project_categories;
