import React from "react";

const GeneralContext = React.createContext({
  actualPage: "",
  projectsOpen: false,
  showProjects: () => {},
  hideProjects: () => {},
  handleMenuClick() {}
});

export const Provider = GeneralContext.Provider;
export const Consumer = GeneralContext.Consumer;
