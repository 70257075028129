import React from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Consumer } from "../../GeneralContext";
import { ProjectsContainer, ProjectOverlay, ProjectCategory } from '../../components/StyledComponents';
import Projects from "./Projects";
import projects from "../../stores/projects";

library.add(faPlusCircle);

export default class Portfolio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: []
    };
  }
  filterProjectsByCategory = categoryId => {
    this.setState({
      projects: projects.filter(project => project.categoryId === categoryId)
    });
  };

  render() {
    const { projectCategories } = this.props;
    return (
      <Consumer>
        {context => {
          return (
            <ProjectsContainer>
              {projectCategories
                .sort((a, b) => a.order - b.order)
                .map(projectCategory => (
                  <ProjectCategory
                    key={projectCategory.slug + "-projects"}
                    bgImage={projectCategory.image}
                  >
                    <ProjectOverlay
                      onClick={e => {
                        e.preventDefault();
                        this.filterProjectsByCategory(projectCategory.id);
                        context.showProjects();
                      }}
                    >
                      <FontAwesomeIcon icon="plus-circle" className="icon" />
                      <h4>{projectCategory.title}</h4>
                    </ProjectOverlay>
                  </ProjectCategory>
                ))}
              <Projects projects={this.state.projects} />
            </ProjectsContainer>
          );
        }}
      </Consumer>
    );
  }
}
