import React from "react";
import { Link } from "@reach/router";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import colors from "../../colors";

const SubmenuWrapper = styled.ul`
  height: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  background: ${colors.bg};
  position: absolute;
  overflow: hidden;
  transition: all 0.3s;

  & > li {
    height: 30px;
    white-space: nowrap;

    & > a {
      line-height: 30px;
      font-size: 14px;
    }
  }
`;

function Submenu(props) {
  const { submenus, context } = props;

  const innerLinks = submenus.map(submenu => (
    <li key={submenu.index.toString()}>
      <Link to={submenu.address} onClick={context.handlePageChange}>
        {submenu.text}
      </Link>
    </li>
  ));
  return <SubmenuWrapper>{innerLinks}</SubmenuWrapper>;
}

Submenu.propTypes = {
  submenus: PropTypes.array
};

export default Submenu;
