import React from "react";
import { Carousel } from "react-bootstrap";

import projectCategories from "../../stores/project_categories";
import { ProjectContainer, ProjectData } from '../../components/StyledComponents';

function Project({ project }) {
  const params = {};
  const projectImages = project.images.keys().map(image => require(`../../images/projects/${image.replace('./', '')}`));

  return (
    <ProjectContainer className="project">
      <Carousel {...params}>
        {projectImages.map(image => {
          return (<Carousel.Item>
            <img key={image} src={image} alt={project.title} />
          </Carousel.Item>
          )
        })}
      </Carousel>
      <ProjectData>
        <h3>
          Portfolio /{" "}
          <span>
            {
              projectCategories.filter(
                projectCategory => projectCategory.id === project.categoryId
              )[0].title
            }
          </span>
        </h3>
        <h4>{project.title}</h4>
        {project.client !== "" ? (
          <div className="client">Cliente: {project.client}</div>
        ) : null}
        <p>
          {typeof project.description === "object"
            ? project.description.join("")
            : project.description}
        </p>
        {project.copyright ? <small>{project.copyright}</small> : null}
        {project.host ? (
          <p>
            {project.multiple_hosts
              ? `Alguns ${project.title.toLowerCase()} foram desenvolvidos`
              : "Desenvolvido"}{" "}
            em: {project.host}.<br />
            Todos os direitos reservados
          </p>
        ) : null}
      </ProjectData>
      <Carousel {...params}>
        {projectImages.map(image => {
          return (<Carousel.Item>
            <img key={image} src={image} alt={project.title} />
          </Carousel.Item>
          )
        })}
      </Carousel>
    </ProjectContainer>
  );
}

export default Project;
