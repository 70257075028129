import React from "react";
import { Link } from "@reach/router";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { Consumer } from "../../GeneralContext";
import Logo from "../../images/logotipo_hands.svg";
import Img from "./Img";
import links from "../../stores/links";
import Submenu from "./Submenu";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import colors from "../../colors";

library.add(faBars);

const NavBar = styled.div`
  height: 0;
  width: 100vw;
  top: 106px;
  overflow: hidden;
  left: 0;
  position: fixed;
  background: ${colors.bg};
  transition: height 0.3s;

  &.open {
    height: calc(100vh - 106px);
  }

  @media (min-width: 320px) {
  }
  @media (min-width: 1224px) {
    height: 106px;
    width: 668px;
    overflow: visible;
    position: relative;
    float: right;
    top: auto;
    left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const MenuItem = styled.div`
  padding: 30px 20px;
  position: relative;

  a {
    display: block;
    text-decoration: none;
    font-family: "Roboto", Helvetica, sans-serif;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    color: ${colors.highlight};
    position: relative;
    width: 100%;
    height: 100%;
    transition: color 0.3s;
  }

  &.active a,
  a:hover {
    color: ${colors.text};
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    left: 50%;
    bottom: -2px;
    height: 5px;
    width: 0;
    background-color: ${colors.text};
    transform: translateX(-50%);
    transition: width 0.3s;
  }
  &.active:after,
  &:hover:after {
    width: 100%;
  }

  @media (min-width: 1224px) {
    padding: 43px 0;

    a {
      display: inline;
      padding: 43px 0;
    }
  }
`;

const MenuWrapper = styled.div`
  position: relative;
  display: table;
  box-sizing: border-box;
  padding: 0 0 0 20px;
  width: 100%;
  height: 106px;
  margin: auto;

  @media (min-width: 320px) {
  }
  @media (min-width: 1224px) {
    width: 960px;
    padding: 0;
  }

  img {
    float: left;
    width: 173px;
    margin: 11px 0 0;
  }
`;

const MenuToggler = styled.div`
  float: right;
  font-size: 30px;
  color: ${colors.text};
  margin: 30px;

  @media (min-width: 1224px) {
    display: none;
  }
`;

const TopBar = styled.div`
  background: ${colors.bg};
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  height: 106px;
  position: sticky;
  top: 0;
  z-index: 100;
`;

/* eslint-disable react/prefer-stateless-function */
class Menu extends React.Component {
  linkClasses = (actualPage, address) => {
    let classes = [];

    if (actualPage === address.replace("/", "")) classes.push("active");

    return classes;
  };

  render() {
    return (
      <Consumer>
        {context => {
          let { actualPage, handleMenuClick, toggleMenu, display } = context;

          return (
            <TopBar className={"menu " + actualPage}>
              <MenuWrapper>
                <Link to="/" onClick={handleMenuClick}>
                  <Img src={Logo} id="logo" alt="hands-criação - Logo" />
                </Link>
                <NavBar className={display ? "open" : ""}>
                  {links.map(link => {
                    if (link.items) {
                      return (
                        <MenuItem
                          className={this.linkClasses(
                            actualPage,
                            link.address
                          ).join()}
                          key={link.index.toString()}
                        >
                          <Link to={link.address} onClick={handleMenuClick}>
                            {link.text}
                          </Link>
                          <Submenu
                            submenus={link.items}
                            actualPage={actualPage}
                          />
                        </MenuItem>
                      );
                    }
                    return (
                      <MenuItem
                        className={this.linkClasses(
                          actualPage,
                          link.address
                        ).join()}
                        key={link.index.toString()}
                      >
                        <Link to={link.address} onClick={handleMenuClick}>
                          {link.text}
                        </Link>
                      </MenuItem>
                    );
                  })}
                </NavBar>
                <MenuToggler onClick={toggleMenu}>
                  <FontAwesomeIcon icon={faBars} />
                </MenuToggler>
              </MenuWrapper>
            </TopBar>
          );
        }}
      </Consumer>
    );
  }
}

Menu.propTypes = {
  actualPage: PropTypes.string,
  display: PropTypes.bool,
  handleMenuClick: PropTypes.func,
  toggleMenu: PropTypes.func
};

export default Menu;
