/*
 * Project
 *
 * Comments
 */

import React from "react";
import { Link } from "@reach/router";

import HomeSwiper from "../../components/HomeSwiper";
import AboutArea from "./AboutArea";
import ServicesArea from "./ServicesArea";
import PortfolioArea from "./PortfolioArea";
import Logo from "../../images/logotipo_hands_branco.svg";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

import images from "./homeImages";
import { PageContainer, Slogan, PreFooter, Footer } from '../../components/StyledComponents';

library.add(faFacebookSquare, faInstagram);

const homePage = () => {
  return (
    <PageContainer>
      <HomeSwiper images={images} />
      <Slogan>
        <h2>O que podemos fazer por sua marca ou empresa?</h2>
        <h3>Envie seu briefing / solicite um orçamento</h3>
      </Slogan>
      <AboutArea />
      <ServicesArea />
      <PortfolioArea />
      <Slogan className="xs">
        <h2>O que podemos fazer por sua marca ou empresa?</h2>
        <h3>Envie seu briefing / solicite um orçamento</h3>
      </Slogan>
      <PreFooter id="area-contato">
        <img src={Logo} alt="Hands Criação" />
        <ul>
          <li>
            <Link to="/">handscriacao.com.br</Link>
          </li>
          <li>
            <a href="mailto:contato@handscriacao.com.br">
              contato@handscriacao.com.br
              </a>
          </li>
          <li>+55 11 97281.8877</li>
          <li>
            <a
              href="https://www.facebook.com/Hands-Cria%C3%A7%C3%A3o-2347478788668944/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 22 }}
                icon={faFacebookSquare}
              />
            </a>
            <a
              href="https://www.instagram.com/handscriacao/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon style={{ fontSize: 22 }} icon={faInstagram} />
            </a>
          </li>
        </ul>
      </PreFooter>
      <Footer>
        <p>&copy; {new Date().getFullYear()} Todos os direitos reservados - Hands Criação</p>
      </Footer>
    </PageContainer>
  );
}

export default homePage;
