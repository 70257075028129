import React from "react";
import styled from "@emotion/styled";

import Portfolio from "./Portfolio";

import colors from "../../colors";
import projectCategories from "../../stores/project_categories";

const PortfolioWrapper = styled.div`
  text-align: center;

  h3 {
    margin: 61px 0 0;

    &:after {
      background-color: ${colors.text};
    }
  }

  & > h4 {
    color: ${colors.highlight};
    margin: 26px 0 0;
    font-size: 23px;
    text-transform: uppercase;
    letter-spacing: -1.6px;
  }
  & > p {
    margin-top: 22px;
    font-size: 15px;
  }
`;

export default function PortfolioArea({ projectsOpen }) {
  return (
    <PortfolioWrapper id="area-projetos">
      <h3>Portfolio</h3>
      <h4>Cases de destaque</h4>
      <p>Alguns projetos que já realizamos.</p>
      <Portfolio
        projectCategories={projectCategories}
        projectsOpen={projectsOpen}
      />
    </PortfolioWrapper>
  );
}
