const projects = [
  {
    id: 1,
    categoryId: 2,
    title: "Academia particular",
    client: "Casa de Alto Padrão no Brooklin / São Paulo",
    description:
      "Ilustrações digitais impressas em adesivos para decoração de academia particular.",
    images: require.context("../images/projects", false, /academia.*\.jpg/)
  },
  {
    id: 2,
    categoryId: 2,
    title: "Decoração de interiores",
    client: "Casa de Alto Padrão na Cantareira / SP",
    description:
      "Projeto da Designer de Interiores Cibele Petrangelo que teve destaque na Revista Decorar. Decoraçẽo de quarto para 2 irmãos adolescentes com ilustrações digitais impressas em adesivo. As ilustrações foram customizadas contemplando as preferências de cada um. A Porsche, sonho de consumo de um dos rapazes e a manobra de skate desenhada a partir de uma foto do irmão.",
    images: require.context("../images/projects", false, /decoracao.*\.jpg/)
  },
  {
    id: 3,
    categoryId: 2,
    title: "Ativação / Evento de premiação",
    client: "De Stijl",
    description:
      "Ilustrações de 15 profissionais do ramo de Arquitetura e Decoração de Interiores para Evento de Premiação da Loja De Stijl em Santo André, SP. As ilustrações viraram quadros impressos em canvas para presentear os profissionais, que também foi destaque no Catálogo do Pólo de Design e Decoração.",
    images: require.context("../images/projects", false, /premiacao.*\.jpg/)
  },
  {
    id: 4,
    categoryId: 2,
    title: "Campeonato de Surf",
    client: "FICO",
    description:
      "Ilustrações digitais em alta qualidade para apresentação de Campeonato de Surf de gerações na Praia da Joaquina em SC. Os desenhos foram utilizados nos formatos impresso e vídeo.",
    images: require.context("../images/projects", false, /campeonato.*\.jpg/)
  },
  {
    id: 5,
    categoryId: 2,
    title: "Infographics de apresentação",
    client: "TETRAPAK",
    description: [
      `"Making food safe and available everywhere... to everyone."`,
      `Ilustrações infográficas em formato pirâmide para apresentação de projeto na Sede da Tetrapak em Dubai. Os desenhos foram utilizados nos formatos digital e para vídeo motion.`
    ],
    images: require.context("../images/projects", false, /info.*\.jpg/)
  },
  {
    id: 6,
    categoryId: 2,
    title: "Mascote",
    client: "",
    description:
      "Criação de mascote para Copinha infantil interna de Futsal para Clube em São Paulo por ocasião da Copa do Mundo.",
    images: require.context("../images/projects", false, /mascote.*\.jpg/)
  },
  {
    id: 7,
    categoryId: 2,
    title: "Ilustrações para cases",
    client: "UNICASE",
    description:
      "Ilustrações para manual de uso de cases Iphone e Ipad, juntamente com projeto de design gráfico do manual.",
    images: require.context("../images/projects", false, /manual.*\.jpg/)
  },
  {
    id: 8,
    categoryId: 2,
    title: "Decoração de ambiente",
    client: "",
    description:
      "Ilustração digital para ambientação de empresa por ocasião dos jogos da Copa do Mundo.",
    images: require.context("../images/projects", false, /ambiente.*\.jpg/)
  },
  {
    id: 9,
    categoryId: 3,
    title: "Institucional",
    client: "Sadia",
    description:
      "Criação, direção e finalização de arte para anúncios impressos em diferentes revistas do cenário nacional.",
    host: "Agência Pepper",
    images: require.context("../images/projects", false, /cases_anuncios_01.*\.jpg/)
  },
  // {
  //   id: 10,
  //   categoryId: 3,
  //   title: "Direção de arte",
  //   client: "Telelok",
  //   description:
  //     "Criação conceitual de anúncio institucional para revistas especializadas, feito em parceria com ilustração de Diovane Blasquez (3d). Sketches (esboços) de Daniel Bota.",
  //   host: "Soluções Marketing",
  //   images: require("../image/projects/cases_anuncios_02.*\.jpg/)
  // },
  {
    id: 11,
    categoryId: 5,
    title: "Jogo da vida do assegurado",
    client: "Porto Seguros",
    description: "Conceito visual em 3d para um game da Porto Seguros.",
    dev: "Diovane Blasquez",
    images: require.context("../images/projects", false, /cases_3d_01.*\.jpg/)
  },
  {
    id: 12,
    categoryId: 5,
    title: "Comercial festa junina",
    client: "Itaipava",
    description:
      "Modelos, texturas, luz e render desenvolvido para o Big Studios.",
    dev: "Diovane Blasquez",
    images: require.context("../images/projects", false, /cases_3d_02.*\.jpg/)
  },
  {
    id: 13,
    categoryId: 5,
    title: "Conceito visual para animação",
    client: "D21",
    description:
      "Design, modelo e render feito para um projeto de conceito visual de uma série animada em 3d.",
    dev: "Diovane Blasquez",
    images: require.context("../images/projects", false, /cases_3d_03.*\.jpg/)
  },
  {
    id: 14,
    categoryId: 5,
    title: "Apartamento virtual",
    client: "Bueno neto",
    description:
      "Apartamento virtual interativo para experiência de visitação com Oculus Rift e Unreal Engine",
    dev: "Diovane Blasquez",
    images: require.context("../images/projects", false, /cases_3d_04.*\.jpg/)
  },
  {
    id: 15,
    categoryId: 5,
    title: "Comercial",
    client: "York",
    description:
      "Comercial de ar condicionado com intuito de mostrar toda a complexa engenharia e eficiência por trás de um projeto.",
    dev: "Diovane Blasquez",
    images: require.context("../images/projects", false, /cases_3d_05.*\.jpg/)
  },
  {
    id: 16,
    categoryId: 5,
    title: "Personagem",
    description:
      "Estudo de personagens guerreiros com alta qualidade de iluminação e render.",
    dev: "Diovane Blasquez",
    copyright: "Propriedade intelectual do grupo Walt Disney.",
    images: require.context("../images/projects", false, /cases_3d_06.*\.jpg/)
  },
  {
    id: 17,
    categoryId: 5,
    title: "Ferrari",
    description:
      "Estudo de carro de corrida em alta qualidade de definição 3d.",
    dev: "Diovane Blasquez",
    images: require.context("../images/projects", false, /cases_3d_07.*\.jpg/)
  },
  // {
  //   id: 18,
  //   categoryId: 3,
  //   title: "Manual",
  //   client: "NEC",
  //   description: "Manual de produtos nas versões impressa e digital.",
  //   host: "Soluções Marketing",
  //   images: require("../image/projects/cases_impressos_nec_.*\.jpg/)
  // },
  // {
  //   id: 19,
  //   categoryId: 3,
  //   title: "Arte conceito",
  //   client: "Kellogg's",
  //   description:
  //     "Criação do logotipo e identidade visual para start conceitual de campanha no formato banner.",
  //   host: "Soluções Marketing",
  //   images: require("../image/projects/cases_impressos_kelloggs_01.*\.jpg/)
  // },
  // {
  //   id: 20,
  //   categoryId: 3,
  //   title: "Livro de receitas",
  //   client: "Kellogg's",
  //   description: "Livrinho com receitas especiais.",
  //   host: "Soluções Marketing",
  //   images: require("../image/projects/cases_impressos_kelloggs_02.*\.jpg/)
  // },
  // {
  //   id: 21,
  //   categoryId: 3,
  //   title: "Catálogo",
  //   client: "Kellogg's",
  //   description: "Catálogo de Produtos.",
  //   host: "Soluções Marketing",
  //   images: require("../image/projects/cases_impressos_kelloggs_03.*\.jpg/)
  // },
  // {
  //   id: 22,
  //   categoryId: 3,
  //   title: "Institucional",
  //   client: "RIMAC",
  //   description:
  //     "Pasta de apresentação institucional com folders separados de empresas parceiras.",
  //   images: require("../image/projects/cases_impressos_rimac_.*\.jpg/)
  // },
  {
    id: 23,
    categoryId: 1,
    title: "Evento corporativo",
    client: "BD",
    description:
      "Evento de premiação para 250 pessoas no Hotel Hard Rock Megapolis / Panamá. Criação de identidade visual e temática para “Sala Brasil“. Convite impresso com acesso para app em tecnologia 4d com scan humano e produção de Vídeos Motion (formato 3326x1200px, para projeção em tela de 16 metros de comprimento).",
    images: require.context("../images/projects", false, /cases_ident_visual_bd_.*\.jpg/)
  },
  {
    id: 24,
    categoryId: 1,
    title: "Campanha de endomarketing",
    client: "PwC Brasil",
    description:
      "Criação de identidade visual para Semana de Conscientização em Mineradora. Peças impressas e brindes de apoio.",
    images: require.context("../images/projects", false, /cases_ident_visual_pwc_.*\.jpg/)
  },
  // {
  //   id: 25,
  //   categoryId: 1,
  //   title: "Cinema itinerante",
  //   client: "Coca-Cola Sorocaba",
  //   description:
  //     "Ação de caráter cultural voltada para comunidades do interior de São Paulo com projeção de filmes através de caminhão itinerante.",
  //   host: "Soluções Marketing",
  //   images: require("../image/projects/cases_ident_visual_cocacola_.*\.jpg/)
  // },
  // {
  //   id: 26,
  //   categoryId: 1,
  //   title: "Convenção de vendas",
  //   client: "LG",
  //   description: "Convenção de Vendas.",
  //   host: "Soluções Marketing",
  //   images: require("../image/projects/cases_ident_visual_lg_.*\.jpg/)
  // },
  {
    id: 27,
    categoryId: 1,
    title: "Carreta Itinerante",
    client: "Honda",
    description:
      "Ação da marca para divulgação das motocicletas e test-drive pelo interior de São Paulo.",
    host: "Zeta2 Eventos",
    images: require.context("../images/projects", false, /cases_ident_visual_honda_.*\.jpg/)
  },
  {
    id: 28,
    categoryId: 1,
    title: "Carreta Itinerante",
    client: "FIAT",
    description:
      "Ação da marca para divulgação da marca e test-drive pelas principais capitais do País.",
    host: "Zeta2 Eventos",
    images: require.context("../images/projects", false, /cases_ident_visual_fiat_.*\.jpg/)
  },
  {
    id: 29,
    categoryId: 6,
    title: "Logos institucionais",
    client: "DIVERSOS",
    description:
      "Logotipos criados para marcas e empresas de diversos segmentos e áreas de atuação.",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_logos_institucionais.*\.jpg/)
  },
  {
    id: 30,
    categoryId: 6,
    title: "Logos esportivos",
    client: "DIVERSOS",
    description:
      "Logotipos criados para marcas e empresas dos segmentos de esportes.",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_logos_esportivos_.*\.jpg/)
  },
  // {
  //   id: 31,
  //   categoryId: 6,
  //   title: "Logos eventos",
  //   client: "DIVERSOS",
  //   description:
  //     "Logotipos para eventos de diversos segmentos e áreas de atuação.",
  //   copyright: "Todos os direitos reservados",
  //   images: require("../image/projects/cases_logos_eventos_.*\.jpg/)
  // },
  {
    id: 32,
    categoryId: 6,
    title: "Brandbook",
    client: "Jardim de Festas",
    description:
      "Criação de marca e Guia de Identidade Visual.",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_brand_jardim_.*\.jpg/)
  },
  {
    id: 33,
    categoryId: 6,
    title: "Academia",
    client: "Rio Vermelho Jiu-Jitsu",
    description:
      "Criação de marca e Guia de Identidade Visual.",
    host: "Soluções Marketing",
    multiple_hosts: true,
    images: require.context("../images/projects", false, /cases_brand_riovermelho_.*\.jpg/)
  },
  // {
  //   id: 34,
  //   categoryId: 2,
  //   title: "Ilustrações para eventos",
  //   client: "Diversos",
  //   description: "Ilustrações para eventos de segmentos variados.",
  //   host: "Soluções Marketing",
  //   images: require("../image/projects/cases_ilustracoes_new.*\.jpg/)
  // },
  {
    id: 35,
    categoryId: 2,
    title: "Decoração de interiores",
    client: "Casa em São Paulo",
    description: "Ilustração digital para decoração de sala.",
    images: require.context("../images/projects", false, /cases_ilustracoes_jordan_.*\.jpg/)
  },
  {
    id: 36,
    categoryId: 4,
    title: "Notícias",
    client: "Smart Cast",
    description:
      "Criação de site ao estilo “notícias”, em plataforma Wordpress com sistema de gerenciamento para o cliente.",
    dev: "Keslon Machado",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_web_keslon_01.*\.jpg/)
  },
  {
    id: 37,
    categoryId: 4,
    title: "Corporativo",
    client: "PdvGroup",
    description:
      "Criação de site corporativo e otimização para mecanismos de buscas (SEO).",
    dev: "Keslon Machado",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_web_keslon_02.*\.jpg/)
  },
  {
    id: 38,
    categoryId: 4,
    title: "Corporativo",
    client: "PB&KM",
    description:
      "Criação de site corporativo, Criação de Identidade visual e otimização para mecanismos de buscas (SEO).",
    dev: "Keslon Machado",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_web_keslon_03.*\.jpg/)
  },
  {
    id: 39,
    categoryId: 4,
    title: "Corporativo",
    client: "Escolas OpenGO",
    description:
      "Reformulação de site corporativo, otimização para mecanismos de buscas (SEO) e gerenciamento de campanhas patrocinadas (Adwords).",
    dev: "Keslon Machado",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_web_keslon_04.*\.jpg/)
  },
  {
    id: 40,
    categoryId: 4,
    title: "Corporativo",
    client: "Aw Contábil",
    description:
      "Reformulação de site corporativo e otimização para mecanismos de buscas (SEO).",
    dev: "Keslon Machado",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_web_keslon_05.*\.jpg/)
  },
  {
    id: 41,
    categoryId: 4,
    title: "Corporativo",
    client: "Suporte ATX",
    description:
      "Criação de site corporativo, otimização para mecanismos de buscas (SEO) e gerenciamento de campanhas patrocinadas (Adwords).",
    dev: "Keslon Machado",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_web_keslon_06.*\.jpg/)
  },
  {
    id: 42,
    categoryId: 4,
    title: "Corporativo",
    client: "Aplicadora Santana",
    description:
      "Reformulação de site corporativo, otimização para mecanismos de buscas (SEO) e gerenciamento de campanhas patrocinadas (Adwords).",
    dev: "Keslon Machado",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_web_keslon_07.*\.jpg/)
  },
  {
    id: 43,
    categoryId: 5,
    title: "Design de produto",
    client: "Esmaltec",
    description:
      "Modelagem e LookDev para visualização de produtos em mídias digitais.",
    dev: "Keslon Machado",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_3d_keslon_02.*\.jpg/)
  },
  {
    id: 44,
    categoryId: 5,
    title: "Design de personagem",
    client: "Ana",
    description:
      "Modelagem e LookDev baseada na história infantil Ana e o Lobo da artista @lisv.art",
    dev: "Keslon Machado",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_3d_keslon_03.*\.jpg/)
  },
  {
    id: 45,
    categoryId: 5,
    title: "Design de personagem",
    client: "Itaú",
    description:
      "Modelagem e LookDev para desenvolvimento de um conceito de personagem para representar a marca.",
    dev: "Keslon Machado",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_3d_keslon_04.*\.jpg/)
  },
  {
    id: 46,
    categoryId: 7,
    title: "Ótica",
    client: "Ótica Cidade",
    description:
      "Artes para redes sociais: Instagram, Facebook e Linkedin.",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_brand_midias_oti.*\.jpg/)
  },
  {
    id: 47,
    categoryId: 7,
    title: "Banco",
    client: "Banco Nobel",
    description:
      "Artes para redes sociais: Instagram, Facebook e Linkedin.",
    copyright: "Todos os direitos reservados",
    images: require.context("../images/projects", false, /cases_brand_midias_ban.*\.jpg/)
  }
];

export default projects;
