import React, { useRef } from "react";
import { Carousel } from "react-bootstrap";
import PropTypes from "prop-types";
import { Slide, SlideContainer } from '../StyledComponents';


const HomeSwiper = (props, { ...styles }) => {
  const swiperRef = useRef(null);

  const images = props.images;

  const params = {
    interval: 4000
  };

  const slides = images.map((slide, index) => {
    const slideText = slide.mainText.split(" ");
    return (
      <Carousel.Item>
        <Slide key={index.toString()} image={slide.image}>
          <div className={"slide-text-wrapper " + slide.align}>
            <p>{slideText[0]}</p>
            {slide.joiner ? (
              <p>
                <span className={slide.newLineJoiner ? "nl" : ""}>
                  {slide.joiner}
                </span>{" "}
                {slideText[1]}
              </p>
            ) : (
                <p>{slideText[1]}</p>
              )}
          </div>
        </Slide>
      </Carousel.Item>
    );
  });

  return <SlideContainer {...styles}>
    <Carousel {...params} ref={swiperRef}>{slides}</Carousel>
  </SlideContainer>;
}

HomeSwiper.propTypes = {
  images: PropTypes.array
};

export default HomeSwiper;
