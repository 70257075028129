/**
 * App.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import React from "react";
import { Helmet } from "react-helmet";
import { Router } from "@reach/router";
import { Global } from "@emotion/core";

import 'bootstrap/dist/css/bootstrap.min.css';

// Import root app
import HomePage from "./containers/HomePage";
// import PortfolioPage from "./containers/PortfolioPage";
// import ProjectPage from "./containers/ProjectPage";
// import AboutPage from "./containers/AboutPage";
// import ContactPage from "./containers/ContactPage";

import { Provider } from "./GeneralContext";
import globalCss from "./global-styles";
import Menu from "./components/Menu";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actualPage: window.location.pathname.split("/")[1] || "",
      display: false,
      projectsOpen: false,
      handleMenuClick: this.handleMenuClick,
      toggleMenu: this.toggleMenu,
      showProjects: this.showProjects,
      hideProjects: this.hideProjects
    };
  }

  toggleMenu = () => {
    this.setState({ display: !this.state.display });
  };
  showProjects = () => {
    this.setState({
      projectsOpen: true
    });
    document.body.style.overflow = "hidden";
  };
  hideProjects = () => {
    this.setState({
      projectsOpen: false
    });
    document.body.style.overflow = "";
  };

  handleMenuClick = event => {
    event.preventDefault();
    let clickedLink = event.target;
    while (clickedLink.nodeName !== "A") clickedLink = clickedLink.parentNode;

    let actualPage = clickedLink.href.split("/").pop(),
      sessionDiv = document.querySelector(`#area-${actualPage}`),
      menuHeight = document.querySelector(".menu").clientHeight || 0,
      newScrollHeight = 0;

    if (actualPage) {
      newScrollHeight = sessionDiv.offsetTop;
    }

    window.scroll({
      top: newScrollHeight - menuHeight,
      left: 0,
      behavior: "smooth"
    });

    if (actualPage !== this.state.actualPage) {
      this.setState({
        actualPage
      });

      if (this.state.display) this.toggleMenu();
    }
  };

  render() {
    return (
      <div>
        <Global styles={globalCss} />
        <Helmet titleTemplate="%s - Hands Criação" defaultTitle="Hands Criação">
          <meta
            name="description"
            content="Grupo de desenvolvimento criativo."
          />
        </Helmet>
        <Provider value={this.state}>
          <Menu />
          <Router>
            <HomePage path="/" />
            <HomePage path="/:session" />
            {/* <PortfolioPage path="/portfolio" />
            <ProjectPage path="/portfolio/:id" />
            <AboutPage path="/sobre" />
            <ContactPage path="/contact" /> */}
          </Router>
        </Provider>
      </div>
    );
  }
}

export default App;
