import colors from "./colors";

const globalCss = {
  "html, body": {
    height: "100%",
    width: "100%",
    padding: 0,
    margin: 0
  },

  body: {
    fontFamily: "'Open Sans', Roboto, Helvetica, Arial, sans-serif",
    backgroundColor: colors.bg
  },

  "body.fontLoaded": {
    fontFamily: "'Open Sans', Roboto, Helvetica, Arial, sans-serif"
  },

  "p, label": {
    fontFamily: "'Open Sans', Roboto, Helvetica, Arial, sans-serif",
    lineHeight: "1.5em"
  }
};

export default globalCss;
